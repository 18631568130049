:root {
  --color-green-superdark: #1c8b71;
  --color-green-dark: #77b7a8;
  --color-green-main: #09bb87;
  --color-green-medium: #61756b;
  --color-green-light: #c8f1de;
  --color-green-lighter: #d7f5e7;
  --color-green-grey: rgba(184, 237, 212, 0.16);
  --color-green-neon: #39ff14;
  --color-blue-dark: #111219;
}
