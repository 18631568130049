.footer {
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--color-blue-dark);
  padding: 0 20px;
  max-width: 1366px;
  width: 100%;
  align-self: center;
}
.footer .menu {
  flex-direction: row;
  align-items: center;
}
.footer .menu a {
  margin-left: 30px;
  text-decoration: none;
}
.footer .menu a.active {
  margin-left: 30px;
  color: var(--color-green-main);
}

@media all and (max-width: 520px) {
  .footer {
    flex-direction: column;
    border-top: 1px solid #444;
    padding-top: 30px;
  }
}
