.section {
  padding: 40px 0;
}
.section.partners {
  align-items: center;
  height: 300px;
  background-image: url("../images/dotted_5.png");
  background-size: contain;
  background-position: center;
  /* justify-content: center; */
  padding-top: 60px;
  margin: 40px 0;
}
.section.partners .inner {
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
img.partner {
  max-height: 50px;
  margin-top: 50px;
}
.hero {
  margin-bottom: 20px;
}
.hero > img {
  width: 100%;
  max-width: 900px;
  align-self: center;
  margin-top: 60px;
}
.hero .apple,
.hero .google {
  margin-top: 60px;
}
.app-btn-wrp {
  flex-direction: row;
  width: 100%;
  max-width: 360px;
  align-self: center;
  justify-content: space-between;
}
.app-btn-wrp .app-btn {
  max-height: 50px;
}
.app-btn-wrp a:hover {
  border-color: transparent;
}
.leaf {
  max-width: 340px;
  align-self: center;
}
@media all and (max-width: 520px) {
  .section {
    padding: 20px 0;
  }
  .hero {
    overflow: hidden;
  }
  .hero h1 {
    font-size: 40px;
  }
  .hero > img {
    width: 140vw;
    max-width: 900px;
    align-self: center;
    margin-top: 60px;
  }
  .leaf {
    max-width: 200px;
  }
  .section.partners {
    justify-content: center;
    padding-top: 10px;
    margin-top: 0;
  }
  .section.partners h2 {
    margin-bottom: 10px;
  }
  img.partner {
    max-height: 40px;
    margin-top: 20px;
  }
}
