.ui-wrapper {
  position: relative;
}
.logo-link {
  align-items: center;
  display: flex;
}
.logo-link:hover {
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
.logo {
  /* width: 50px;
  height: 50px; */
  max-width: 100px;
  border-radius: 25px;
}
.content-wrapper {
  padding: 40px 20px;
  max-width: 1366px;
  align-self: center;
  width: 100%;
}
a {
  border-bottom: 2px solid transparent;
}
a:hover {
  border-bottom: 2px solid var(--color-green-main);
}
button.download-cta {
  color: #fff;
  border: 2px solid var(--color-green-main);
  padding: 10px 15px;
  border-radius: 12px;
  transition: background-color 0.4s ease;
  text-align: center;
  max-width: 154px;
  background-color: transparent;
}
button.download-cta:hover {
  color: #fff;
  background-color: var(--color-green-main);
  transition: background-color 0.4s ease;
}
a.arrow {
  content: ">";
  color: var(--color-green-main);
  align-items: center;
  position: relative;
  align-self: flex-start;
  padding-right: 20px;
}
a.arrow::after {
  content: ">";
  margin-left: 10px;
  font-size: 20px;
  position: absolute;
  right: 0;
  top: -2px;
}
.cols {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}
.one-col {
  width: 100%;
  justify-content: flex-start;
}
.two-col {
  width: 47%;
  justify-content: flex-start;
}
.three-col {
  width: 28%;
  justify-content: flex-start;
}
.four-col {
  width: 20%;
  justify-content: flex-start;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}
.modal .inner {
  position: absolute;
  /* top: 150px;
  right: calc(100vw / 2 - 250px); */
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 300px;
  background: var(--color-green-main);
  transition: opacity 1s ease;
  opacity: 0;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.modal.show .inner {
  opacity: 1;
  transition: opacity 1s ease;
}
.modal h2 {
  margin-bottom: 30px;
}
@media all and (max-width: 520px) {
  .content-wrapper {
    padding-top: 0;
  }
  .modal .inner {
    width: 90vw;
    height: 60vw;
    right: 5vw;
    padding: 0 20px;
    margin-bottom: 40px;
  }
  .modal .innder .app-btn-wrp {
    justify-content: space-around;
  }
  .modal .inner .app-btn-wrp .app-btn {
    max-height: 44px;
  }
  .cols {
    flex-direction: column;
  }
  .two-col,
  .three-col,
  .four-col {
    width: 100%;
    margin-bottom: 25px;
  }
  .cols > .empty {
    display: none;
  }
  button.download-cta:hover {
    background-color: transparent;
  }
  .logo {
    max-width: 80px;
  }
}
