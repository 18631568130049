* {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: var(--color-blue-dark);
}
div {
  display: flex;
  flex-direction: column;
}
h1 {
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 10px;
}
h2 {
  font-weight: 600;
  font-size: 34px;
  margin-bottom: 10px;
}
h3 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
}

h4 {
  font-weight: 500;
  font-size: 18px;
  color: var(--color-green-main);
  margin-bottom: 10px;
}
p {
  margin-bottom: 15px;
}
a {
  text-decoration: none;
  font-weight: 400;
}
